<template>
  <calendar :date-mark-definitions="dateMarkDefinitions" :date-marks="dateMarks"
            @changecurrent="handleMonthChange"></calendar>
</template>

<script>
import Calendar from "@/components/calendar/Calendar";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import {WeightLevel} from "@/assets/javascript/weight-utils";

export default {
  name: "WeightCalendar",
  mixins: [httpapi],
  components: {Calendar},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {

      dateMarkDefinitions: [
        /**
         * 体重正常
         */
        {
          backgroundColor: '#faf4f5',
        },
        /**
         * 体重偏低
         */
        {
          backgroundColor: '#d2e3f1',
        },
        /**
         * 体重偏高
         */
        {
          backgroundColor: '#e3c7ee',
        }
      ],

      dateMarks: {},

      /**
       * 按月份分组存储体重日记录
       */
      weightDateNodeListMonthMap: {},

      /**
       * 正在加载的月份
       */
      loadingMonthMap: {},

    }
  },

  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },

  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.loadingMonthMap = {};
        this.weightDateNodeListMonthMap = {};
        this.loadWeightDateListByMonth(new Date());
      },
      immediate: true,
    }
  },

  methods: {

    handleMonthChange: function (e) {
      this.loadWeightDateListByMonth(e.month);
    },

    notifyDateChange: function (date) {
      if (date && this.userId && this.organizationId) {
        this.loadWeightDateListByMonth(date);
      }
    },

    updateDateMarks: function () {
      let dateMarks = {};

      let weightDateNodeListMonthMap = this.weightDateNodeListMonthMap;
      for (let monthKey in weightDateNodeListMonthMap) {
        let weightDateList = weightDateNodeListMonthMap[monthKey];
        for (let dateNode of weightDateList) {
          let markIndex = null;
          switch (dateNode.value.level) {
            case WeightLevel.NORMAL:
              markIndex = 0;
              break;
            case WeightLevel.UNDER_WEIGHT:
              markIndex = 1;
              break;
            case WeightLevel.OVER_WEIGHT:
            case WeightLevel.FAT_LEVEL_I:
            case WeightLevel.FAT_LEVEL_II:
            case WeightLevel.FAT_LEVEL_III:
              markIndex = 2;
              break;
          }
          if (markIndex != null) {
            dateMarks[dateNode.date] = markIndex;
          }
        }
      }
      this.dateMarks = dateMarks;
    },

    loadWeightDateListByMonth: function (month) {
      if (!this.userId || !this.organizationId) return;
      let monthStart = TimeUtils.format('yyyy-MM-dd', TimeUtils.getMonthStart(month));
      if (this.loadingMonthMap[monthStart]) return;
      this.loadingMonthMap[monthStart] = true;

      this.$reqGet({
        path: '/mt/weight/date/list/get/by_month',
        data: {
          userId: this.userId,
          dateInMonth: monthStart,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let weightDateList = res.data;
            this.weightDateNodeListMonthMap[monthStart] = weightDateList;
            this.updateDateMarks();
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            delete this.loadingMonthMap[monthStart];
          })
    }
  }
}
</script>

<style scoped>

</style>
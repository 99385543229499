<template>
  <ys-infinite-scroll-view class="weight-list" @loadmore="loadWeightDateListBefore">
    <weight-list-item
        :ref="`list_item_${dateNode.date}`"
        v-for="(dateNode) in weightDateList"
        :key="dateNode.date"
        :value="dateNode"
        :user-id="userId"
        :organization-id="organizationId"
    ></weight-list-item>
    <div class="font-size-small font-align-center font-color-placeholder">已滑动到最后</div>
  </ys-infinite-scroll-view>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import WeightListItem from "@/pages/minitools/weight/components/WeightListItem";
import {TimeUtils} from "@/assets/javascript/time-utils";
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "WeightList",
  mixins: [httpapi],
  components: {WeightListItem, YsInfiniteScrollView},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 体重日节点列表
       */
      weightDateList: [],

    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },

  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.loadWeightDateListBefore();
      }
    }
  },

  mounted() {
    this.loadWeightDateListBefore();
  },

  methods: {

    notifyDateChange: function (date) {
      if (date) {
        date = TimeUtils.format('yyyy-MM-dd', date);
        this.loadWeightDateNode(date);
        let listItem = this.$refs[`list_item_${date}`];
        listItem?.notifyDateChange(date);
      }
    },

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    loadWeightDateListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.weightDateList.length > 0 ? this.weightDateList[this.weightDateList.length - 1].date : null;
      this.$reqGet({
        path: '/mt/weight/date/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let weightDateList = this.weightDateList;
            this.$appendByOrder(weightDateList, dateNodeList, "date", "DESC");
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },

    loadWeightDateNode: function (date) {
      if (!this.userId || !this.organizationId) return;

      date = TimeUtils.format('yyyy-MM-dd', date);
      this.$reqGet({
        path: '/mt/weight/date/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNode = res.data;
            let weightDateList = this.weightDateList;
            if (dateNode) {
              this.$appendByOrder(weightDateList, [dateNode], "date", "DESC");
            } else {
              for (let n = weightDateList.length - 1; n >= 0; n--) {
                if (weightDateList[n].date === date) {
                  weightDateList.splice(n, 1);
                  break;
                }
              }
            }
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="weight-list-item">
    <div class="layout-horizontal" @click="handleClick">
      <time-line
          :title="value.date"
      ></time-line>
      <div class="layout-horizontal layout-middle padding-vertical-large margin-left layout-flexible">
        <div class="font-size-small margin-right">日平均：</div>
        <div class="font-size-medium">{{ weightStr }}kg</div>
        <div class="font-size-medium font-color-placeholder margin-left-large" v-if="bmiStr">BMI: {{ bmiStr }}</div>
        <div class="margin-left-large">
          <span :class="weightLevelClass">{{ weightLevelStr }}</span>
        </div>
        <div class="layout-flexible"></div>
        <div class="padding-right-large">
          <span class="font-color-placeholder">
            <i class="fas fa-angle-down" v-if="!isExpend"></i>
            <i class="fas fa-angle-up" v-else></i>
          </span>
        </div>
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="isExpend && dailyWeightList.length > 0">
        <div class="daily-weight-item layout-horizontal layout-middle"
             v-for="timeNode in dailyWeightList"
             :key="timeNode.value.id">
          <time-line
              :title="timeNode.timeStr"
              color="#ffc4cd"
              class="layout-self-stretch"
          >
          </time-line>
          <span class="font-color-placeholder font-size-medium padding-vertical-large margin-left">{{ timeNode.timeStr }}</span>
          <span class="font-size-medium margin-left">{{ timeNode.value.weightStr }} kg</span>
        </div>
      </div>
    </el-collapse-transition>
  </div>

</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import ExactNumber from "@/assets/javascript/exact-number";
import {TimeUtils} from "@/assets/javascript/time-utils";
import {WeightLevel, WeightUtils} from "@/assets/javascript/weight-utils";
import TimeLine from "@/pages/minitools/components/TimeLine";

export default {
  name: "WeightListItem",
  components: {TimeLine},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
    value: Object,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 是否展开每一天得详细数据
       */
      isExpend: false,

      /**
       * 日体重列表
       */
      dailyWeightList: [],

    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId, value} = this;
      return {
        userId,
        organizationId,
        value,
      }
    },
    weightStr: function () {
      return ExactNumber.stringify(this.value?.value.weight);
    },
    weightLevelStr: function () {
      return WeightUtils.getWeightLevelStr(this.value?.value.level);
    },
    weightLevelClass: function () {
      let level = this.value?.value.level;
      if (!level) return "";
      switch (level) {
        case WeightLevel.NORMAL:
          return "font-color-success";
        case WeightLevel.OVER_WEIGHT:
          return "font-color-warning";
        case WeightLevel.UNDER_WEIGHT:
          return "font-color-primary";
        case WeightLevel.FAT_LEVEL_I:
        case WeightLevel.FAT_LEVEL_II:
        case WeightLevel.FAT_LEVEL_III:
          return "font-color-danger";
        default:
          return "";
      }
    },
    bmiStr: function () {
      return ExactNumber.stringify(this.value?.value.bmi);
    },
    dateStr: function () {
      return TimeUtils.format('yyyy/MM/dd', this.value?.date);
    }
  },

  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.dailyWeightList = [];
        this.isExpaned = false;
      }
    },
    isExpend: function () {
      if (this.isExpend) {
        if (this.dailyWeightList.length == 0) {
          this.loadDateRecord();
        }
      }
    }
  },

  methods: {
    handleClick: function () {
      this.isExpend = !this.isExpend
    },

    isBusy: function () {
      return this.busyLoadingCode == this.loadingCode;
    },

    loadDateRecord: function () {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      this.$reqGet({
        path: '/mt/weight/date_record/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date: this.value.date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateRecord = res.data;
            for (let timeNode of dateRecord.value.weightList) {
              timeNode.timeStr = TimeUtils.format('HH:mm', '2021-11-11 ' + timeNode.time);
              timeNode.value.weightStr = ExactNumber.stringify(timeNode.value.weight);
            }
            let weightList = new Array(dateRecord.value.weightList.length);
            for (let n = 0; n < weightList.length; n++) {
              weightList[n] = dateRecord.value.weightList[weightList.length - n - 1];
            }
            this.dailyWeightList = weightList;
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    }


  }
}
</script>

<style scoped>

.weight-list-item {
  cursor: pointer;
}

.weight-list-item:hover {
  background-color: #f1f1f1;
}

</style>
<template>
  <linear-chart :serials="chartSerials" @scrolltoleft="handleScrollToLeft" @scrolltoright="handleScrollToRight"></linear-chart>
</template>

<script>
import LinearChart from "@/components/chart/LinearChart";
import httpapi from "@/assets/javascript/httpapi";
import {WeightUtils} from "@/assets/javascript/weight-utils";
import {TimeUtils} from "@/assets/javascript/time-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import {DateChartConverter} from "@/components/chart/DateChartConverter";

export default {
  name: "WeightLinearChart",
  mixins: [httpapi],
  components: {LinearChart},
  props: {

    userId: Number,

    organizationId: Number,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 体重列表
       */
      weightDateList: [],

      /**
       * 图形参数
       */
      chartSerials: [],

    }
  },

  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },

  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.loadWeightDateListBefore();
      },
      immediate: true,
    }
  },

  methods: {

    handleScrollToLeft: function () {
      this.loadWeightDateListBefore();
    },

    handleScrollToRight: function () {
      this.loadWeightDateListAfter();
    },

    notifyDateChange: function (date) {
      if (date && this.userId && this.organizationId) {
        this.loadWeightDateNode(date);
      }
    },

    updateChartSerials: function () {
      let serial = {
        color: '#f091a6',
        key: 'weight',
        values: [],
        converter: new DateChartConverter(1),
      }

      let weightDateList = this.weightDateList;
      for (let dateNode of weightDateList) {
        let val = ExactNumber.toFloat(dateNode.value.weight);


        serial.values.push({
          key: dateNode.date,
          val,
          valLabel: `${ExactNumber.stringify(dateNode.value.weight)}kg ${WeightUtils.getWeightLevelStr((dateNode.value.level))}`
        });
      }

      this.chartSerials = [serial];

    },

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    loadWeightDateListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;

      this.busyLoadingCode = this.loadingCode;

      let date = this.weightDateList.length > 0 ? this.weightDateList[0].date : null;
      this.$reqGet({
        path: '/mt/weight/date/list/get/before',
        data: {
          userId: this.userId,
          date,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let weightDateList = this.weightDateList;
            this.$appendByOrder(weightDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },

    loadWeightDateListAfter: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.weightDateList.length > 0 ? this.weightDateList[this.weightDateList.length - 1].date : null;
      this.$reqGet({
        path: '/mt/weight/date/list/get/after',
        data: {
          userId: this.userId,
          date,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let weightDateList = this.weightDateList;
            this.$appendByOrder(weightDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },

    loadWeightDateListAround: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);
      this.$reqGet({
        path: '/mt/weight/date/list/get/around',
        data: {
          userId: this.userId,
          date,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let weightDateList = this.weightDateList;
            this.$appendByOrder(weightDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadWeightDateNode: function (date) {
      if (!this.userId || !this.organizationId) return;

      date = TimeUtils.format('yyyy-MM-dd', date);

      this.$reqGet({
        path: '/mt/weight/date/get',
        data: {
          userId: this.userId,
          date,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNode = res.data;
            let weightDateList = this.weightDateList;
            if (dateNode) {
              this.$appendByOrder(weightDateList, [dateNode], "date", "ASC");
            } else {
              for (let n = weightDateList.length - 1; n >= 0; n--) {
                if (weightDateList[n].date === date) {
                  weightDateList.splice(n, 1);
                  break;
                }
              }
              this.updateChartSerials();
            }
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="weight-page font-align-left padding layout-horizontal" style="overflow: hidden">
    <div class="card layout-inflexible" style="overflow: hidden;" v-if="false">
      <div class="padding-top-large padding-horizontal-large padding-bottom"
           style="background-color: #f091a6; color: white;">
        <user-info :user-id="userId" :organization-id="organizationId" class="margin-bottom"></user-info>
        <div v-if="heightStr || targetWeightStr" class="margin-bottom">
          <span v-if="targetWeightStr">目标体重：{{ targetWeightStr }} kg</span>
          <span v-if="heightStr && targetWeightStr">，</span>
          <span v-if="heightStr">身高：{{ heightStr }} cm</span>
        </div>
      </div>
      <weight-calendar :user-id="userId" :organization-id="organizationId"
                       class="margin-bottom-large margin-horizontal-small"></weight-calendar>
    </div>
    <div class="layout-flexible layout-vertical margin-left">
      <div class="layout-vertical" style="height: 100%;">
        <div class="title font-size-large layout-inflexible margin-left-large margin-bottom font-weight-bold">用户体重</div>
        <div class="card layout-inflexible">
          <div class="font-weight-bold padding-horizontal-large padding-top-large"><i class="fas fa-chart-line"></i> 趋势</div>
          <weight-linear-chart
              :user-id="userId"
              :organization-id="organizationId"
              style="height: 200px;"
              class="layout-flexible margin-bottom-large"
          ></weight-linear-chart>
        </div>
        <div class="card margin-top layout-flexible layout-vertical"
             style="overflow: hidden;">
          <div class="font-weight-bold layout-inflexible padding-horizontal-large padding-top-large padding"><i class="fas fa-list"></i> 记录</div>
          <weight-list
              :user-id="userId"
              :organization-id="organizationId"
              class="layout-flexible padding-bottom-large"
          ></weight-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import WeightCalendar from "@/pages/minitools/weight/components/WeightCalendar";
import WeightLinearChart from "@/pages/minitools/weight/components/WeightLinearChart";
import WeightList from "@/pages/minitools/weight/components/WeightList";
import ExactNumber from "@/assets/javascript/exact-number";
import UserInfo from "@/pages/minitools/components/UserInfo";

export default {
  name: "MiniToolWeight",
  components: {UserInfo, WeightList, WeightLinearChart, WeightCalendar},
  mixins: [httpapi],
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 输入参数
       */
      userId: null,
      organizationId: null,

      /**
       * 体重参数设置
       */
      weightParams: null,
    }
  },

  computed: {
    heightStr: function () {
      return ExactNumber.stringify(this.weightParams?.height);
    },
    targetWeightStr: function () {
      return ExactNumber.stringify(this.weightParams?.targetWeight);
    },
  },

  mounted() {
    this.loadingCode++;
    this.userId = this.$route.query.userId;
    this.organizationId = this.$route.query.organizationId;
    this.loadWeightParams();
  },

  methods: {

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },


    loadWeightParams: function () {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      this.$reqGet({
        path: '/mt/weight/params/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date: TimeUtils.format('yyyy-MM-dd', new Date()),
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let weightParams = res.data;
            this.weightParams = weightParams;
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })

    },
  }
}
</script>

<style scoped>

.weight-page {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}


</style>
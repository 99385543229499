export const WeightLevel = {
    /**
     * 未知
     */
    UNKNOWN: 0,

    /**
     * 过低
     */
    UNDER_WEIGHT: 1,

    /**
     * 正常
     */
    NORMAL: 2,

    /**
     * 过重
     */
    OVER_WEIGHT: 3,

    /**
     * I度肥胖
     */
    FAT_LEVEL_I: 4,

    /**
     * II度肥胖
     */
    FAT_LEVEL_II: 5,

    /**
     * III度肥胖
     */
    FAT_LEVEL_III: 6,
}

function getWeightLevelStr(level) {
    if (!level) return "";
    switch (level) {
        case WeightLevel.UNDER_WEIGHT:
            return "过轻";
        case WeightLevel.NORMAL:
            return "正常";
        case WeightLevel.OVER_WEIGHT:
            return "过重";
        case WeightLevel.FAT_LEVEL_I:
            return "I度肥胖";
        case WeightLevel.FAT_LEVEL_II:
            return "II度肥胖";
        case WeightLevel.FAT_LEVEL_III:
            return "III度肥胖";
        default:
            return "";
    }
}

export const WeightUtils = {
    getWeightLevelStr,
}